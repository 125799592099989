import React from 'react';
import './EventDescription.css';

const EventDescription = () => (
  <div className="event-desc-container">
    <h2 className="event-desc-invite">/////////// / You've been invited to: DANCE + + + + Do you accept? (Y/n):</h2>
    <h3 className="event-desc-lineup">{' CIPHER • T4C0 • SKYE... • PSYLOSUB • TIEDYE SAMVRAI • SUSPENSE • FROOTBVSKT • NIKK3 LOV3 '}</h3>
    
    <div className="event-desc-details">
      <p><strong>Date:</strong> FRI, February 07, 2025</p>
      <p><strong>Time:</strong> 10:00pm - 5:00am</p>
      <p><strong>Location:</strong> [ 825 SE MADISON ST. PDX ]</p>
      <p><strong>Age:</strong> 21+</p>
      <p>$10 PRESALES | $15 AT THE DOOR  | $20 AFTER 2AM</p>
    </div>
    
    <h3 className="event-desc-expect">DESCRIPTION:</h3>
    
    
    <p className="event-desc-reentry">Join us as we takeover a new warehouse venue located in Portland's Central Eastside District. </p>
    
    <p className="event-desc-contact">
      If you have any questions, please do not hesitate to reach out to us at <a href="mailto:support@meleemusic.net">support@meleemusic.net</a> or on instagram @melee_music
    </p>
    
    <p className="event-desc-outro">We are excited to have you with us as we continue exploring sounds of the underground.</p>
    <p className="event-desc-outro">See you soon! 😈</p>

   
  </div>
);

export default EventDescription;